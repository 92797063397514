<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <article class="tile is-child box">
                    <h4 class="title">Агенты</h4>

                    <nav class="level">
                        <div class="level-left">
                            <div class="level-item">
                                <p class="control has-addons">
                                    <input v-model.trim="query.search" type="text" placeholder="Поиск по фио, id или uuid" class="input" @keyup.enter="search">
                                    <a class="button is-info" :class="{ 'is-loading': loading }" @click.prevent="search">Найти</a>
                                </p>
                            </div>
                        </div>
                        <div class="level-right">
                            <p class="level-item">
                                <router-link :to="{ name: 'Agent', params: { id: 'new' }}" class="button is-success">Добавить</router-link>
                            </p>
                        </div>
                    </nav>

                    <div class="notification is-primary" v-if="query.search && !haveItems()">
                        Ничего не найдено
                    </div>

                    <spinner v-show="loading"></spinner>

                    <table class="table" v-if="haveItems() && !loading">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>ФИО</th>
                            <th>AGENT_MEDECINS_ID</th>
                            <th>Создан</th>
                            <th colspan="1"></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(agent, index) in items">
                            <td>{{ agent.id }}</td>
                            <td>{{ agent.full_name }}</td>
                            <td>{{ agent.medecins_id }}</td>
                            <td>{{ agent.created_at | dateTime }}</td>
                            <td class="is-icon">
                                <router-link :to="{ name: 'Agent', params: { id: agent.id }}">
                                    <i class="fa fa-edit"></i>
                                </router-link>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <pagination
                            @change="changePage"
                            :total-pages="pagination.total"
                            :limit="pagination.limit"
                            :page="pagination.current"
                    ></pagination>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import agents from '../../services/agents'
import itemListViewMixin from '../../mixins/itemListView'

export default {
  mixins: [itemListViewMixin(agents, 'agents', true)]
}
</script>
