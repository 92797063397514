import BaseService from './baseService'

class AgentService extends BaseService {
  get url () {
    return 'agents'
  }

  createModel () {
    return {
      id: null,
      uuid: null,
      medecins_id: null,
      full_name: '',
      email: '',
      phone: '',
      snils: '',
      date_of_birth: '',
      passport_no: '',
      passport_date_of_issue: '',
      passport_place_of_issue: '',
      passport_address_of_registration: '',
      checking_account: '',
      bic: '', // Bank Identification Code
      correspondent_account: ''
    }
  }
}

export default new AgentService()

